
import Button from "@/components/base/Button"
import OImage from "@/components/base/image/OImage"
import { mdiCheck } from "@mdi/js"
import { OABALYTICS_INDEX_CLICK } from "@/enums/oabalytics"

export default {
    name: "AppDownloadSection",
    components: { OImage, Button },
    data: () => ({
        mdiCheck,
        phoneNumber: "",
        status: "none",
    }),
    computed: {
        downloadLink() {
            if (this.$ua.isFromAndroidOs()) {
                return "https://play.google.com/store/apps/details?id=de.oabat"
            }
            if (this.$ua.isFromIos()) {
                return "https://apps.apple.com/de/app/oabat/id1507176599"
            }
            return undefined
        },
    },
    methods: {
        trackClick(params) {
            this.$oabalytics.trackEvent(OABALYTICS_INDEX_CLICK, params)
        },
    },
}
